import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  StoreDevtoolsModule,
  StoreDevtoolsOptions,
} from '@ngrx/store-devtools';

import {
  AppAction,
  UserAction,
  LeaderboardAction,
  MissionTrackingAction,
} from '../stored/global/actions';
import {
  AppReducer,
  UserReducer,
  LeaderboardReducer,
} from '../stored/global/reducers';
import { AppEffect } from '../stored/global/effects';
import { AppState } from '../stored/global/states';

import { environment } from 'environment';
import { LeaderboardState, MissionTrackingState } from '../models';
import { UserInfoStore } from '../stored';
import { MissionTrackingReducer } from '../stored/global/reducers/mission-tracking.reducer';

export { AppState };

interface NgRxState {
  App: AppState;
  UserInfo: UserInfoStore;
  Leaderboard: LeaderboardState;
  MissionTracking: MissionTrackingState;
}

const NgRxReducer = {
  App: AppReducer,
  UserInfo: UserReducer,
  Leaderboard: LeaderboardReducer,
  MissionTracking: MissionTrackingReducer,
};

const NgRxEffects = [AppEffect];

const Reducer = {
  AppReducer,
  UserReducer,
  LeaderboardReducer,
  MissionTrackingReducer,
};

const Action = {
  AppAction,
  UserAction,
  LeaderboardAction,
  MissionTrackingAction,
};

export { NgRxState, NgRxReducer, NgRxEffects, Reducer, Action };

const storeDevOpts: StoreDevtoolsOptions = {
  maxAge: 32,
  logOnly: false,
};

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(NgRxReducer),
    // EffectsModule.forRoot(NgRxEffects),
    !environment.production ? StoreDevtoolsModule.instrument(storeDevOpts) : [],
  ],
  exports: [
    StoreModule,
    EffectsModule,
    !environment.production ? StoreDevtoolsModule : [],
  ],
})
export class AppNgrxModule {}
