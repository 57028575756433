/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Injectable } from '@angular/core';
import {
  animationFrameScheduler,
  BehaviorSubject,
  finalize,
  map,
  Observable,
  observeOn,
  share,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private readonly eventLoopAction = new BehaviorSubject<boolean[]>([]);
  private readonly eventSkeletonLoopAction = new BehaviorSubject<boolean[]>([]);
  public isLoading = false;
  public isSkeletonLoading = true;
  public isFixLoading = false;
  public isEmpty = false;
  public hideSkeletonId: any;
  public hideFixLoadingId: number[] = [];

  readonly isLoading$ = this.eventLoopAction.pipe(
    map((eventLoop) => eventLoop.length > 0),
    observeOn(animationFrameScheduler),
    tap((isLoading) => (this.isLoading = isLoading)),
    share()
  );

  readonly isSkeletonLoading$ = this.eventSkeletonLoopAction.pipe(
    map((eventLoop) => eventLoop.length > 0),
    observeOn(animationFrameScheduler),
    tap((isLoading) => (this.isLoading = isLoading)),
    share()
  );

  public showLoading<T>() {
    return (source: Observable<T>) =>
      new Observable<T>((observer) => {
        this.addTask();
        return source
          .pipe(finalize(() => this.removeTask()))
          .subscribe(observer);
      });
  }
  public showSkeletonLoading<T>() {
    return (source: Observable<T>) =>
      new Observable<T>((observer) => {
        this.addTaskSkeletonLoading();
        return source
          .pipe(finalize(() => this.removeTaskSkeletonLoading()))
          .subscribe(observer);
      });
  }

  private addTaskSkeletonLoading() {
    const eventLoop = this.eventLoopAction.value;
    eventLoop.push(true);
    this.eventSkeletonLoopAction.next(eventLoop);
  }

  private removeTaskSkeletonLoading() {
    const eventLoop = this.eventLoopAction.value;
    eventLoop.pop();
    this.eventSkeletonLoopAction.next(eventLoop);
  }

  private addTask() {
    const eventLoop = this.eventLoopAction.value;
    eventLoop.push(true);
    this.eventLoopAction.next(eventLoop);
  }

  private removeTask() {
    const eventLoop = this.eventLoopAction.value;
    eventLoop.pop();
    this.eventLoopAction.next(eventLoop);
  }

  public showSkeleton(cd: ChangeDetectorRef, delayTime: number = 30000) {
    if (!this.isSkeletonLoading) {
      this.isSkeletonLoading = true;
      cd.detectChanges();
      this.hideSkeleton(cd, delayTime);
    }
  }

  public hideSkeleton(cd: ChangeDetectorRef, delayTime: number = 500) {
    if (this.isSkeletonLoading) {
      this.hideSkeletonId = setTimeout(() => {
        this.isSkeletonLoading = false;
        cd.detectChanges();
      }, delayTime);
    }
  }

  public showFixLoading(cd: ChangeDetectorRef, delayTime: number = 30000) {
    this.clearAllTimeout();
    if (!this.isFixLoading) {
      this.isFixLoading = true;
      cd.detectChanges();
      this.hideFixLoading(cd, delayTime);
    }
  }

  public hideFixLoading(cd: ChangeDetectorRef, delayTime: number = 500) {
    if (this.isFixLoading) {
      const id = setTimeout(() => {
        this.isFixLoading = false;
        cd.detectChanges();
      }, delayTime);
      this.hideFixLoadingId.push(Number(id));
    }
  }

  private clearAllTimeout() {
    this.hideFixLoadingId.forEach((id) => {
      window.clearTimeout(id);
    });
  }

  // hide background when navigate by deeplink ex: page=history&campaignCode=9
  public showEmpty(cd: ChangeDetectorRef, delayTime: number = 30000) {
    if (!this.isEmpty) {
      this.isEmpty = true;
      cd.detectChanges();
      this.hideEmpty(cd, delayTime);
    }
  }

  public hideEmpty(cd: ChangeDetectorRef, delayTime: number = 500) {
    if (this.isEmpty) {
      setTimeout(() => {
        this.isEmpty = false;
        cd.detectChanges();
      }, delayTime);
    }
  }
}
