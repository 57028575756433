/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  BUTTON_STYLE,
  NAVIGATE_CONFIG,
  NAVIGATE_REQUIRED_ACTION,
} from '../configs';
import {
  MissionTracking,
  MissionTrackingList,
  ButtonNavigateConfig,
  PointBalance,
} from '../models';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  public isMissionExpired = false;
  public isMissionStarted = true;
  public isMissionDisplayExpired = false;

  public isBannerExpired(missionTracking: MissionTrackingList): boolean {
    const now = dayjs();
    const endDate = dayjs(missionTracking?.endDate);

    return now.isAfter(endDate);
  }

  public isMissionTrackingActive(
    missionTracking: MissionTracking
  ): [boolean, boolean] {
    const now = dayjs();
    const startDate = dayjs(missionTracking?.startDate);
    const endDate = dayjs(missionTracking?.endDate);
    const displayEndDate = dayjs(missionTracking?.displayEndDate);

    const isStarted = now.isAfter(startDate) || now.isSame(startDate);

    const isExpired =
      (now.isAfter(endDate) && now.isBefore(displayEndDate)) ||
      now.isSame(displayEndDate);

    return [isExpired, isStarted];
  }

  public isMissionDisabled(
    missionTracking?: MissionTrackingList | MissionTracking
  ): boolean {
    return (
      missionTracking !== undefined &&
      missionTracking.isAllRanOut === true &&
      !missionTracking.isMissionCompleted
    );
  }

  public getButtonNavigate(
    missionTracking?: MissionTracking
  ): ButtonNavigateConfig {
    const action = missionTracking?.navigateToRequireAction ?? 'default';
    const config = NAVIGATE_CONFIG[action] || NAVIGATE_CONFIG['default'];

    return config === NAVIGATE_CONFIG['PC_ACTIVATE'] &&
      !missionTracking?.isHaveCard
      ? NAVIGATE_CONFIG['PC_REGISTER']
      : config;
  }

  public getButtonType(
    missionTracking?: MissionTracking,
    pointBalance?: PointBalance
  ): BUTTON_STYLE {
    if (!missionTracking || !pointBalance) return BUTTON_STYLE.DISABLED;

    const { availableRewardCount, navigateToRequireAction } = missionTracking;
    const [isExpired, isStarted] =
      this.isMissionTrackingActive(missionTracking);

    if (availableRewardCount && availableRewardCount > 0 && isStarted) {
      return BUTTON_STYLE.RECEIVE_REWARD;
    }

    if (
      missionTracking.isMissionCompleted ||
      this.isMissionDisabled(missionTracking) ||
      isExpired ||
      !isStarted
    ) {
      return BUTTON_STYLE.HIDDEN;
    }

    if (
      navigateToRequireAction &&
      navigateToRequireAction !== NAVIGATE_REQUIRED_ACTION.NO_NAVIGATION
    ) {
      return BUTTON_STYLE.NAVIGATE;
    }

    return BUTTON_STYLE.DISABLED;
  }

  public isButtonDisabled(buttonStyle: BUTTON_STYLE): boolean {
    return buttonStyle === BUTTON_STYLE.DISABLED;
  }

  public isButtonVisible(buttonStyle: BUTTON_STYLE): boolean {
    return buttonStyle !== BUTTON_STYLE.HIDDEN;
  }

  public getButtonClass(buttonStyle: BUTTON_STYLE): string {
    return buttonStyle === BUTTON_STYLE.RECEIVE_REWARD
      ? 'cp-btn-primary w-100 p-6'
      : 'cp-btn-primary-spend w-100 p-6';
  }

  public getButtonText(
    buttonStyle: BUTTON_STYLE,
    missionTracking?: MissionTracking,
    balance?: number
  ): string {
    if (!missionTracking) return '';

    const balanceValue = balance ?? 0;

    return buttonStyle === BUTTON_STYLE.NAVIGATE
      ? this.getButtonNavigate(missionTracking).buttonLabel || ''
      : missionTracking.buttonMessageJson !== null
      ? missionTracking.buttonMessageJson[
          buttonStyle === BUTTON_STYLE.DISABLED && balanceValue > 0
            ? balanceValue + 1
            : balanceValue
        ]?.buttonMessage || ''
      : missionTracking.missionImageJson[
          buttonStyle === BUTTON_STYLE.DISABLED && balanceValue > 0
            ? balanceValue + 1
            : balanceValue
        ]?.buttonMessage || '';
  }

  public getRewardId(
    missionTracking: MissionTracking,
    pointBalance: PointBalance
  ): number | undefined {
    return missionTracking.rewards.find(
      (reward) =>
        !reward.isCollected &&
        !reward.isRanOut &&
        reward.unitToRedeem &&
        reward.unitToRedeem <= pointBalance.balance
    )?.id;
  }

  public changeSource(event: any, defaultImage: string) {
    event.target.src = defaultImage;
  }
}
