export enum GA_EVENT_ACTION {
  MIGRATION = 'Migration',
  CLICK = 'Click',
  SLIDE = 'Slide',
  COMPLETE = 'Complete',
  ERROR = 'Error',
  AUTHENTICATE = 'Authenticate',
  SCREEN_VIEW = 'screen_view',
  VIEW = 'View',
}

export enum GA_EVENT_CATEGORY {
  LANDING = 'MissionLanding',
  TRACKING = 'MissionTracking',
}

export enum GA_FIREBASE_PARAMS_KEY {
  PT_INFORMATION = 'pt_information',
  PRODUCT_TYPE = 'product_type',
  NEXT_INFORMATION = 'Information',
  HTTP_STATUS_CODE = 'HTTP Status Code',
  ERROR_CODE = 'Error Code',
  IS_FINANCIAL_TRANSACTION_SYSTEM_ERROR = 'Is Financial Transaction System Error',
  ERROR_TITLE = 'Error Title',
  NEXT_PRODUCT_TYPE = 'Product Type',
}

export enum GA_CUSTOM_DIMENSION_KEY_NEXT {
  INFORMATION = '4',
  HTTP_STATUS_CODE = '5',
  ERROR_CODE = '6',
  IS_FINANCIAL_TRANSACTION_SYSTEM_ERROR = '7',
  ERROR_TITLE = '8',
  PRODUCT_TYPE = '16',
}
