/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';

import { catchError, Observable, switchMap, throwError, timeout } from 'rxjs';

import {
  ERROR_TYPE,
  FE_CODE,
  GA_EVENT_ACTION,
  MISSION_SCREEN,
} from '../../configs';
import { Endpoint } from '../../configs/endpoint.config';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { HttpService } from '../../services/http.service';
import { environment } from 'environment';
import { NavigationService } from '../../services/navigation.service';
@Injectable()
export class EventInterceptorService implements HttpInterceptor {
  token$ = this.httpService.token$;

  private fullErrorList = [
    Endpoint.Banner.List,
    Endpoint.Campaign.Detail,
    Endpoint.Campaign.Statement,
    Endpoint.Auth.Generate,
    Endpoint.Leaderboard.History,
    Endpoint.MissionTracking.PointHistories,
    Endpoint.MissionTracking.PointBalance,
    Endpoint.MissionTracking.Rewards,
    Endpoint.MissionTracking.List,
  ];

  private maintenanceScreenList = [
    MISSION_SCREEN.LANDING,
    MISSION_SCREEN.TRACKING_LEADERBOARD,
    MISSION_SCREEN.TRACKING_LEADERBOARD_HISTORY,
  ];

  constructor(
    private errorHandler: ErrorHandlerService,
    private httpService: HttpService,
    private navigationService: NavigationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // skip for json
    const isJsonFile = req.url.includes('json');
    if (isJsonFile) {
      return next.handle(req);
    }

    // remove undefined param
    let params = req.params;
    for (const key of req.params.keys()) {
      if (params.get(key) === undefined) {
        params = params.delete(key, undefined);
      }
    }
    req = req.clone({ params });

    this.errorHandler.isMaintenance = false;

    return this.token$.pipe(
      switchMap((token) => {
        // add authorization
        let request = req.clone();
        const isApiUrl = req.url.startsWith(environment.service.host);
        if (isApiUrl) {
          if (/token\/generate/.test(req.url)) {
            if (token) {
              if (this.httpService.isRefreshToken) {
                this.httpService.isRefreshToken = false;
                request = req.clone({
                  setHeaders: {
                    'authorization-code': `${this.httpService.authorizationCodeParam}`,
                  },
                });
              } else {
                request = req.clone({
                  setHeaders: { 'authorization-code': `${token}` },
                });
              }
            } else {
              this.errorHandler.setError(ERROR_TYPE.FULLSCREEN_API, {
                screen: MISSION_SCREEN.LANDING,
                errorMessage: 'No token',
                code: FE_CODE.NO_AUTHORIZATION_CODE,
                customMessage: 'No token',
              });
            }
          } else {
            request = req.clone({
              setHeaders: {
                Authorization: `Bearer ${this.httpService.verifyToken}`,
              },
            });
          }
        }

        return next.handle(request).pipe(
          timeout(11000),
          catchError((error: HttpErrorResponse) => {
            return this.handleError({ ...error, url: request.url });
          })
        );
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status !== HttpStatusCode.Ok) {
      const url = this.convertToEndpoint(error.url || '', '?');
      if (navigator.onLine) {
        this.httpService.isOffline = false;
        this.httpService.isOffline$.next(false);

        const isFullScreenError =
          this.fullErrorList.includes(url) ||
          this.fullErrorList.includes(this.convertToEndpoint(url || '', '/'));
        const isTimeoutError = error?.name?.toLowerCase().includes('timeout');

        if (isTimeoutError) {
          error = {
            ...error,
            statusText: FE_CODE.TIMEOUT,
            message: `${FE_CODE.TIMEOUT}//${error.name} ${error.url}`,
          };
          if (isFullScreenError) {
            this.errorHandler.setError(ERROR_TYPE.FULLSCREEN_API, {
              screen: MISSION_SCREEN.API_ERROR,
              errorMessage: 'Timeout',
              code: FE_CODE.TIMEOUT,
              customMessage: `${error.name} ${error.url}`,
            });
          }
        } else if (isFullScreenError) {
          this.handleFullscreenError(error);
        } else if (url.includes(Endpoint.Leaderboard.Detail.split('/{')[0])) {
          // ignore (handle per screen)
          this.handleFullscreenError(error);
        } else if (!url.includes(Endpoint.CheckIn.Artwork.split('/{')[0])) {
          const newError = { ...error };
          if (
            error instanceof HttpErrorResponse &&
            (error.status === 0 ||
              error?.status === HttpStatusCode.ServiceUnavailable)
          ) {
            newError.status = HttpStatusCode.Forbidden;
          }
          this.errorHandler.setError(ERROR_TYPE.POPUP, {
            screen: MISSION_SCREEN.API_ERROR,
            errorMessage: 'On click error',
            httpError: newError,
            code: newError.status,
            ga: {
              event: {
                action: GA_EVENT_ACTION.ERROR,
                category:
                  this.navigationService.screen || MISSION_SCREEN.API_ERROR,
                label: `${newError.status}//${newError.message}`,
              },
              firebaseParam: [],
            },
          });
        }
      } else {
        setTimeout(() => {
          this.httpService.isOffline = true;
          this.httpService.isOffline$.next(true);
        }, 1000);
      }
    }
    return throwError(() => error);
  }

  private convertToEndpoint(uri: string, character: string) {
    return uri.indexOf(character) > 0
      ? uri.substring(0, uri.lastIndexOf(character))
      : uri;
  }

  private handleFullscreenError(error: HttpErrorResponse) {
    if (
      error.status === 0 ||
      error?.status === HttpStatusCode.ServiceUnavailable
    ) {
      const newError = { ...error, status: HttpStatusCode.Forbidden };
      this.maintenanceScreenList.includes(this.navigationService.screen)
        ? this.errorHandler.onMaintenance(newError)
        : this.errorHandler.setError(ERROR_TYPE.FULLSCREEN_API, {
            screen: 'COMMON' as MISSION_SCREEN,
            errorMessage: 'Maintenance',
            httpError: newError,
            code: newError.status,
            ga: {
              event: {
                action: GA_EVENT_ACTION.ERROR,
                category: MISSION_SCREEN.FULLSCREEN_ERROR,
                label: `${newError.status}//${newError.message}`,
              },
              firebaseParam: [],
            },
          });
      this.errorHandler.navigationService.forceExit = true;
    } else {
      this.errorHandler.setError(ERROR_TYPE.FULLSCREEN_API, {
        screen: 'COMMON' as MISSION_SCREEN,
        errorMessage: 'On load api error',
        httpError: error,
        code: error.status,
        ga: {
          event: {
            action: GA_EVENT_ACTION.ERROR,
            category: MISSION_SCREEN.FULLSCREEN_ERROR,
            label: `${error.status}//${error.message}`,
          },
          firebaseParam: [],
        },
      });
    }
  }
}
