import { environment } from 'environment';

export const Endpoint = {
  Campaign: {
    Statement: `${environment.service.host}/api/v1/web-campaign-statement-api/campaign/statement`,
  },
  CheckIn: {
    Process: `${environment.service.host}/api/v1/web-checkIn-api/process`,
  },
  Leaderboard: {
    Detail: `${environment.service.host}/api/v1/orch-mbcp-leaderboard-inquiry/{campaignCode}/detail`,
    History: `${environment.service.host}/api/v1/orch-mbcp-referral-code-inquiry/history`,
    PreviousRankPopup: `${environment.service.host}/api/v1/orch-mbcp-leaderboard-inquiry/{campaignCode}/reward-popup/seen`,
  },
  MissionTracking: {
    List: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-manage/list`,
    detail: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-manage/{missionTrackingID}/detail`,
    PointHistories: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-manage/point-histories`,
    PointBalance: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-manage/point-balance`,
    PopupSeen: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-manage/popup/seen`,
    Rewards: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-manage/rewards`,
  },
  TrackingRedeem: {
    PollingCoupon: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-redeem/polling-coupon`,
    Redeem: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-redeem/redeem`,
    CheckIn: `${environment.service.host}/api/v1/orch-mbcp-mission-tracking-redeem/checkin`,
  },
  Auth: {
    Generate: `${environment.service.host}/api/v1/web-auth-api/token/generate`,
  },
};
