/* eslint-disable @typescript-eslint/no-explicit-any */
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { firstValueFrom, Subject } from 'rxjs';
import {
  GA_FIREBASE_PARAMS_KEY,
  GA_EVENT_ACTION,
  MISSION_SCREEN,
  PAGE_NAME,
  ROUTE_PATH,
  CHANNEL,
  PRODUCT,
  DESTINATION,
  COMMAND,
} from '../configs';
import { MissionTrackingStore } from '../stored';
import { JsInterfaceService } from './js-interface.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _history: string[] = [];
  public get history(): string[] {
    return this._history;
  }
  public set history(value: string[]) {
    this._history = value;
  }

  public backEvt = new Subject<void>();
  public closeEvt = new Subject<void>();
  public changePageEvt = new Subject<void>();
  public refreshEvt = new Subject<void>();
  public isShowNav$ = new Subject<boolean>();
  public isLoading$ = new Subject<boolean>();
  public enableLoadingSpinner$ = new Subject<boolean>();

  public screen: MISSION_SCREEN = MISSION_SCREEN.LANDING;
  public forceExit = false;
  public isAndroid = false;
  public scrollPercent = 0;
  public queryParam = { campaignCode: null };

  constructor(
    private router: Router,
    private location: Location,
    private missionTrackingStore: MissionTrackingStore,
    private jsInterface: JsInterfaceService,
    private httpService: HttpService
  ) {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    if (isAndroid) {
      this.isAndroid = true;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  goTo(route: string, query: any = {}) {
    this.changePageEvt.next();
    this.router.navigate([route], { queryParams: query });
  }

  goToExternal(pathWithParam: string) {
    window.location.replace(pathWithParam);
  }

  goBack(forcedExit = false) {
    this.changePageEvt.next();
    this.isShowNav$.next(false);
    this.history.pop();
    if (this.screen === MISSION_SCREEN.LANDING || this.forceExit) {
      this.goToHome();
    } else if (
      ([MISSION_SCREEN.TRACKING, MISSION_SCREEN.TRACKING_LEADERBOARD].includes(
        this.screen
      ) &&
        this.queryParam?.campaignCode === null) ||
      forcedExit
    ) {
      if (this.httpService.product === PRODUCT.PTP)
        this.goTo(ROUTE_PATH.LANDING);
      else this.goToHome();
    } else if (
      this.screen === MISSION_SCREEN.TRACKING_EARN_AND_BURN_HISTORY ||
      (MISSION_SCREEN.TRACKING_EARN_AND_BURN_REWARD.includes(this.screen) &&
        this.history.length === 1)
    ) {
      this.goTo(ROUTE_PATH.EARN_AND_BURN);
    } else if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  async goToHome() {
    this.jsInterface.navigation({
      command: COMMAND.ROUTING,
      content: {
        destination: DESTINATION.BACK,
      },
    });
  }

  public getRoutePath(screen: MISSION_SCREEN) {
    const indexOfScreen = Object.values(MISSION_SCREEN).indexOf(
      screen as unknown as MISSION_SCREEN
    );
    return indexOfScreen > -1 ? Object.values(PAGE_NAME)[indexOfScreen] : '';
  }

  public close() {
    this.goToHome();
    this.taggingBack('_Close');
    this.closeEvt?.next();
  }

  public async taggingBack(suffix: string = '_Back') {
    const missionTrackingId = await firstValueFrom(
      this.missionTrackingStore.missionTrackingId$
    );
    const firebaseParams = [];

    if (missionTrackingId) {
      firebaseParams.push({
        key: GA_FIREBASE_PARAMS_KEY.PT_INFORMATION,
        value: `campaignCode: ${missionTrackingId}`,
      });
    }

    this.jsInterface.gaTagging(
      {
        action: GA_EVENT_ACTION.CLICK,
        category: this.screen,
        label: this.screen + suffix,
      },
      firebaseParams
    );
  }

  public setScreen(screenName: MISSION_SCREEN, isAppFlyer = false) {
    this.screen = screenName;
    this.tagGA(this.httpService.channel as CHANNEL, screenName, isAppFlyer);
  }

  private async tagGA(
    channel: CHANNEL,
    screenName: MISSION_SCREEN,
    isAppFlyer = false
  ) {
    const missionTrackingId = await firstValueFrom(
      this.missionTrackingStore.missionTrackingId$
    );

    let event = {
      action: GA_EVENT_ACTION.SCREEN_VIEW,
      category: this.screen,
      label: `${screenName}_View`,
    };

    if (channel === CHANNEL.NEXT) {
      event = {
        action: GA_EVENT_ACTION.VIEW,
        category: this.screen,
        label: `${screenName}`,
      };
    }

    this.jsInterface.gaTagging(
      {
        action: event.action,
        category: event.category,
        label: '',
      },
      missionTrackingId
        ? [
            {
              key: GA_FIREBASE_PARAMS_KEY.PT_INFORMATION,
              value: `campaignCode: ${missionTrackingId}`,
            },
          ]
        : undefined
    );

    if (isAppFlyer) {
      this.jsInterface.gaTagging(
        {
          action: event.action,
          category: event.category,
          label: event.label,
        },
        undefined,
        true,
        false
      );
    }
  }
}
