import { createAction, props } from '@ngrx/store';
import {
  MissionTracking,
  MissionLandingDetail,
  MissionTrackingList,
  Rewards,
  PointBalance,
} from '../../../models';

export const SetMissionTrackingId = createAction(
  'SetMissionTrackingId',
  props<{ missionTrackingId: number }>()
);

export const SetMissionLandingDetail = createAction(
  'SetMissionLandingDetail',
  props<{ missionLandingDetail: MissionLandingDetail }>()
);

export const SetMissionTrackingList = createAction(
  'SetMissionTrackingList',
  props<{ missionTrackingList: MissionTrackingList[] }>()
);

export const SetMissionTracking = createAction(
  'SetMissionTracking',
  props<{ missionTracking: MissionTracking }>()
);

export const SetPointBalance = createAction(
  'SetPointBalance',
  props<{ pointBalance: PointBalance }>()
);

export const SetReward = createAction(
  'SetReward',
  props<{ reward: Rewards }>()
);

export const SetIsLeaderboard = createAction(
  'SetIsLeaderboard',
  props<{ isLeaderboard: boolean }>()
);

export const ResetMissionTrackingDetail = createAction(
  'ResetMissionTrackingDetail'
);
export const Reset = createAction('Reset');
