/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Event } from '../models';
import { KeyValue, Navigate, ShareToFriend } from '../models/js.interface';
import { HttpService } from './http.service';
import {
  CHANNEL,
  GA_CUSTOM_DIMENSION_KEY_NEXT,
  GA_FIREBASE_PARAMS_KEY,
} from '../configs';

export interface JavascriptCallback {
  gaTagging(data: any): any;
  onReceiveJSCommand(data: any): any;
  onRetrievePayloadString(data: any): any;
}

declare const campaignWeb: JavascriptCallback;
declare const nativeInterface: JavascriptCallback;
declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class JsInterfaceService {
  constructor(private httpService: HttpService) {}

  public gaTagging(
    event: Event,
    firebaseParam?: KeyValue[],
    trackAppsflyer = false,
    trackGA = true
  ) {
    const isNEXT = this.httpService.channel === CHANNEL.NEXT;
    let customDimensions;

    if (firebaseParam && isNEXT) {
      firebaseParam = this.setKeyForNEXT(firebaseParam);
      customDimensions = this.setCustomDimension(firebaseParam);
    }

    const data = {
      ga: {
        trackGA,
        ...(trackAppsflyer && { trackAppsflyer }),
        event,
        firebaseParam,
        ...(isNEXT && { customDimensions }),
      },
    };

    console.log(data, 'gaTagging');

    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = nativeInterface;
        console.log('isAndroid');
        android.onRetrievePayloadString(body);
      } else {
        window.webkit.messageHandlers.nativeInterface.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
      // handle when open on web
    }
  }

  public shareToFriend(data: ShareToFriend) {
    console.log(data, 'shareToFriend');

    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = campaignWeb;
        console.log('isAndroid');
        android.onReceiveJSCommand(body);
      } else {
        window.webkit.messageHandlers.nativeInterface.postMessage(data);
      }
    } catch (error) {
      console.log('isWeb');
      // handle when open on web
    }
  }

  public navigation(data: Navigate) {
    const isNEXT = this.httpService.channel === CHANNEL.NEXT;
    let customDimensions;
    let firebaseParam = data.ga?.firebaseParam;

    if (data.ga?.firebaseParam && isNEXT) {
      firebaseParam = this.setKeyForNEXT(data.ga?.firebaseParam);
      customDimensions = this.setCustomDimension(data.ga?.firebaseParam);
    }

    const finalData: Navigate = {
      ...data,
      content: {
        ...data.content,
        ...(data.content?.destination !== 'BACK' && {
          additionalParams: `${data.content.additionalParams}${
            this.httpService.channel
              ? '&channel=' + this.httpService.channel
              : ''
          }${
            this.httpService.product
              ? '&product=' + this.httpService.product
              : ''
          }`,
        }),
      },
      ...(data.ga && {
        ga: {
          ...data.ga,
          trackGA: true,
          firebaseParam,
          ...(isNEXT && { customDimensions }),
        },
      }),
    };
    console.log('navigate ', finalData);
    const body = JSON.stringify(finalData);
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    try {
      if (isAndroid) {
        const android = nativeInterface;
        console.log('isAndroid');
        android.onRetrievePayloadString(body);
      } else {
        window.webkit.messageHandlers.nativeInterface.postMessage(body);
      }
    } catch (error) {
      console.log('isWeb');
    }
  }

  public setFirebaseParamError(
    httpStatusCode: number,
    errorCode: number | string,
    errorTitle: string
  ): KeyValue[] {
    return [
      {
        key: GA_FIREBASE_PARAMS_KEY.HTTP_STATUS_CODE,
        value: `${httpStatusCode}`,
      },
      {
        key: GA_FIREBASE_PARAMS_KEY.ERROR_CODE,
        value: `${errorCode}`,
      },
      {
        key: GA_FIREBASE_PARAMS_KEY.IS_FINANCIAL_TRANSACTION_SYSTEM_ERROR,
        value: 'False',
      },
      {
        key: GA_FIREBASE_PARAMS_KEY.ERROR_TITLE,
        value: errorTitle,
      },
    ];
  }

  private setKeyForNEXT(firebaseParam: KeyValue[]): KeyValue[] {
    firebaseParam.map((data, index) => {
      firebaseParam[index].key =
        data.key === GA_FIREBASE_PARAMS_KEY.PT_INFORMATION
          ? GA_FIREBASE_PARAMS_KEY.NEXT_INFORMATION
          : data.key === GA_FIREBASE_PARAMS_KEY.PRODUCT_TYPE
          ? GA_FIREBASE_PARAMS_KEY.NEXT_PRODUCT_TYPE
          : data.key;
    });

    return firebaseParam;
  }

  private setCustomDimension(firebaseParam: KeyValue[]): KeyValue[] {
    return (
      firebaseParam?.map((data) => {
        const indexOfKeys = Object.keys(GA_CUSTOM_DIMENSION_KEY_NEXT).indexOf(
          data.key
            .toUpperCase()
            .replace(/ /g, '_') as unknown as GA_CUSTOM_DIMENSION_KEY_NEXT
        );

        const key =
          indexOfKeys >= 0
            ? Object.values(GA_CUSTOM_DIMENSION_KEY_NEXT)[indexOfKeys]
            : data.key;
        return { key: key, value: data.value };
      }) || []
    );
  }
}
