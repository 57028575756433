import { Injectable } from '@angular/core';
import { CoreFetch } from './core/core';
import { map, Observable } from 'rxjs';
import { Endpoint } from '../configs/endpoint.config';
import {
  MissionTrackingDetailResponse,
  MissionTrackingListRequest,
  MissionTrackingListResponse,
  PointBalanceRequest,
  PointBalanceResponse,
  PopupSeenRequest,
  PopupSeenResponse,
  RewardsRequest,
  RewardsResponse,
} from '../models';
import { TransformTool } from '../tools/transform.tool';
import { BE_CODE, ERROR_TYPE, MISSION_SCREEN } from '../configs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MissionTrackingFetchService extends CoreFetch {
  constructor(
    private errorHandler: ErrorHandlerService,
    private translateService: TranslateService
  ) {
    super();
  }

  public list(
    query?: MissionTrackingListRequest
  ): Observable<MissionTrackingListResponse> {
    return this.get<MissionTrackingListResponse>(
      `${
        Endpoint.MissionTracking.List
      }${TransformTool.convertObjectToQueryString(query)}`
    ).pipe(
      map((response: MissionTrackingListResponse) => {
        if (response.code !== BE_CODE.SUCCESS) {
          this.errorHandler.setError(
            ERROR_TYPE.FULLSCREEN_API,
            {
              screen: MISSION_SCREEN.LANDING,
              errorMessage: response.errorData?.errorMessage || '',
              code: response.code,
            },
            {
              error_title:
                response.errorData?.errorTitle ||
                this.translateService.instant('ERROR.GENERIC.TITLE'),
              error_message:
                response.errorData?.errorMessage ||
                this.translateService.instant('ERROR.GENERIC.TEXT'),
            }
          );
        }
        return response;
      })
    );
  }

  public detail(
    missionTrackingID: string
  ): Observable<MissionTrackingDetailResponse> {
    return this.get<MissionTrackingDetailResponse>(
      Endpoint.MissionTracking.detail.replace(
        '{missionTrackingID}',
        missionTrackingID
      )
    ).pipe(
      map((response: MissionTrackingDetailResponse) => {
        if (
          response.code !== BE_CODE.SUCCESS &&
          response.code !== BE_CODE.CAMPAIGN_EXPIRED &&
          response.code !== BE_CODE.NOT_IN_SEGMENT
        ) {
          this.errorHandler.setError(
            ERROR_TYPE.FULLSCREEN_API,
            {
              screen: MISSION_SCREEN.LANDING,
              errorMessage: response.errorData?.errorMessage || '',
              code: response.code,
            },
            {
              error_title:
                response.errorData?.errorTitle ||
                this.translateService.instant('ERROR.GENERIC.TITLE'),
              error_message:
                response.errorData?.errorMessage ||
                this.translateService.instant('ERROR.GENERIC.TEXT'),
            }
          );
        }
        return response;
      })
    );
  }

  public pointBalance(
    query?: PointBalanceRequest
  ): Observable<PointBalanceResponse> {
    return this.get<PointBalanceResponse>(
      `${
        Endpoint.MissionTracking.PointBalance
      }${TransformTool.convertObjectToQueryString(query)}`
    ).pipe(
      map((response: PointBalanceResponse) => {
        if (response.code !== BE_CODE.SUCCESS) {
          this.errorHandler.setError(
            ERROR_TYPE.FULLSCREEN_API,
            {
              screen: MISSION_SCREEN.TRACKING,
              errorMessage: response.errorData?.errorMessage || '',
              code: response.code,
            },
            {
              error_title:
                response.errorData?.errorTitle ||
                this.translateService.instant('ERROR.GENERIC.TITLE'),
              error_message:
                response.errorData?.errorMessage ||
                this.translateService.instant('ERROR.GENERIC.TEXT'),
            }
          );
        }
        return response;
      })
    );
  }

  public rewards(query?: RewardsRequest): Observable<RewardsResponse> {
    return this.get<RewardsResponse>(
      `${
        Endpoint.MissionTracking.Rewards
      }${TransformTool.convertObjectToQueryString(query)}`
    ).pipe(
      map((response: RewardsResponse) => {
        if (response.code !== BE_CODE.SUCCESS) {
          this.errorHandler.setError(
            ERROR_TYPE.FULLSCREEN_API,
            {
              screen: MISSION_SCREEN.TRACKING,
              errorMessage: response.errorData?.errorMessage || '',
              code: response.code,
            },
            {
              error_title:
                response.errorData?.errorTitle ||
                this.translateService.instant('ERROR.GENERIC.TITLE'),
              error_message:
                response.errorData?.errorMessage ||
                this.translateService.instant('ERROR.GENERIC.TEXT'),
            }
          );
        }
        return response;
      })
    );
  }

  public popupSeen(req: PopupSeenRequest): Observable<PopupSeenResponse> {
    return this.post<PopupSeenResponse>(
      Endpoint.MissionTracking.PopupSeen,
      req
    );
  }
}
