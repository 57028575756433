import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { ERROR_TYPE, GA_EVENT_ACTION, MISSION_SCREEN } from '../configs';
import { ErrorDetail, ErrorResponse, Event, KeyValue } from '../models';
import { JsInterfaceService, NavigationService } from '.';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  public hasError = false;
  public isPdfError = false;
  public errorType: ERROR_TYPE | undefined;
  public errorResp: ErrorDetail | undefined;
  public clearEvt = new Subject<void>();
  public errorEvt = new Subject<void>();
  public errorTitle: string | undefined;
  public errorMessage: string | undefined;
  public isMaintenance = false;
  public fullScreenErrorFlag = false;

  constructor(
    private jsInterface: JsInterfaceService,
    public navigationService: NavigationService
  ) {}
  public clear() {
    this.hasError = false;
    this.errorType = undefined;
    this.clearEvt.next();
  }

  public async setError(
    errorType: ERROR_TYPE,
    errorResp?: ErrorDetail,
    errorData?: ErrorResponse,
    tagGa: boolean = true
  ) {
    this.hasError = true;
    this.errorType = errorType;
    this.errorResp = errorResp;
    this.errorTitle = errorData?.error_title;
    this.errorMessage = `${errorData?.error_message}\n${
      errorResp?.code ? '[' + errorResp?.code + ']' : ''
    }`;
    console.error(`ERROR:`, errorResp || errorData);
    this.navigationService.screen =
      errorType === ERROR_TYPE.FULLSCREEN_API
        ? MISSION_SCREEN.FULLSCREEN_ERROR
        : this.navigationService.screen;

    if (tagGa) {
      this.addGaTagging({
        code: errorResp?.code
          ? errorResp?.code
          : errorResp?.httpError?.status !== undefined
          ? errorResp?.httpError?.status
          : '',
        message: errorResp?.customMessage
          ? errorResp?.customMessage
          : errorResp?.httpError
          ? errorResp.httpError.statusText
          : errorData?.error_title || ' ' + errorData?.error_message || '',
        ga: errorResp?.ga,
      });
    }

    this.errorEvt.next();
  }

  public async addGaTagging(data: {
    code: string | number;
    message: string;
    ga?: {
      event: Event;
      firebaseParam?: KeyValue[];
    };
  }) {
    if (data.ga) {
      this.jsInterface.gaTagging(data.ga.event, data.ga.firebaseParam);
    } else {
      this.jsInterface.gaTagging({
        action: GA_EVENT_ACTION.ERROR,
        category: this.navigationService.screen,
        label: `${data.code}//${data.message}`,
      });
    }
  }

  public isFullScreenError() {
    return (
      (this.hasError &&
        [
          ERROR_TYPE.FULLSCREEN_API,
          ERROR_TYPE.INCOMING,
          ERROR_TYPE.ONLOAD,
        ].includes(this.errorType as ERROR_TYPE)) ||
      this.errorType === ERROR_TYPE.FULLSCREEN_CONNECTION
    );
  }

  public onMaintenance(error: HttpErrorResponse) {
    this.isMaintenance = true;
    this.setError(
      ERROR_TYPE.FULLSCREEN_API,
      {
        screen: MISSION_SCREEN.LANDING,
        errorMessage: 'Maintenance',
        httpError: error,
        code: error.status,
        ga: {
          event: {
            action: GA_EVENT_ACTION.ERROR,
            category: MISSION_SCREEN.FULLSCREEN_ERROR,
            label: `${error.status}//Maintenance`,
          },
          firebaseParam: [],
        },
      },
      {
        error_title: 'ปิดปรับปรุงระบบบริการ\nภารกิจรับรางวัลชั่วคราว',
        error_message:
          'ขออภัยในความไม่สะดวก\nเนื่องจากอยู่ระหว่างการปรับปรุงระบบ\nเพื่อให้มีประสิทธิภาพที่ดียิ่งขึ้น',
      }
    );
  }
}
